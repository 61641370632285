:root {
    --mainText: white;
    --secondaryText: peachpuff;
    --thirdText: #00A5E9;
    --forthText: #0458AB;
    --darkBgColor: #051537;
    --lightBgColor: #00A5E9;
    --borderColor: white;
}

html,
body {
    font-family: 'DM Serif Display', serif;
    color: var(--mainText);
    background: url("assets/dark-paths-bg.jpg");
    background-color: var(--darkBgColor);
    background-size: auto;
    scroll-behavior: smooth;
}

h3 {
    color: var(--forthText);
}

h2 {
    color: var(--thirdText);
}

h4,
h1 {
    color: var(--secondaryText);
}

h5,
h6 {
    color: black;
    text-align: center;
}

.bg-navy {
    background: var(--darkBgColor);
}

.bg-light-blue {
    background: var(--lightBgColor);
}

.bg-peach {
    background: gray;
}


.title {
    font-size: 3rem;
}

.resize-photo {
    height: 300px;
}

.navbar {
    width: 75%;
    text-align: justify;
    font-size: 23px;
    text-transform: uppercase;
}

.row {
    margin: 0;
}

/* To remove underlined after clicking a hyperlink */
a,
a:active,
a:visited,
a:focus {
    text-decoration: none;
    color: peachpuff;
}

.about-img-bg {
    width: 50%;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, peachpuff, transparent);
    display: grid;
    place-items: center;
    text-align: center;
}

#about_img {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

#about_img:hover {
    transform: rotate(0);
}

/* Navigation bar styling */
nav {
    background: var(--darkBgColor);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.4rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.8rem;
    border-radius: 50%;
    display: flex;
    color: var(--forthText);
    font-size: 1.1rem;
}

nav a:hover {
    background: var(--lightBgColor);
}

nav a.active {
    background: var(--forthText);
    color: var(--mainText);
}

.shadow {
    box-shadow: 0 6px 20px rgb(0 0 0 / 20%);
}

.border-navy {
    border-radius: 50px;
    border-color: #051537;
}

.fix-width {
    width: 400px;
}

.text-light-blue {
    color: #00A5E9;
}

.bg-peach {
    background-color: var(--secondaryText);
    ;
}

.portfolio-photo {
    height: 45%;
    border-radius: 20px;
    text-align: center;
    margin: auto;
}

#live-demo {
    color: #00A5E9;
}

.logo {
    width: 20px;
}


/* The actual timeline (the vertical ruler) */
.main-timeline {
    position: relative;
}

/* The actual timeline (the vertical ruler) */
.main-timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: peachpuff;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

/* Container around content */
.timeline {
    position: relative;
    background-color: inherit;
    width: 50%;
}

/* The circles on the timeline */
.timeline::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -13px;
    background-color: #051537;
    border: 5px solid #00A5E9;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}


/* Place the container to the left */
.left {
    padding: 0px 40px 20px 0px;
    left: 0;
}

/* Place the container to the right */
.right {
    padding: 0px 0px 20px 40px;
    left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
    content: " ";
    position: absolute;
    top: 18px;
    /* z-index: 1; */
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right::before {
    content: " ";
    position: absolute;
    top: 18px;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
    left: -12px;
}

/* Media queries - Responsive timeline on screens less than 1000px wide */
@media screen and (max-width: 1000px) {

    /* Place the timelime to the left */
    .main-timeline::after {
        left: 31px;
    }

    /* Full-width containers */
    .timeline {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
    }

    /* Make sure that all arrows are pointing leftwards */
    .timeline::before {
        left: 60px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }

    /* Make sure all circles are at the same spot */
    .left::after,
    .right::after {
        left: 18px;
    }

    .left::before {
        right: auto;
    }

    /* Make all right containers behave like the left ones */
    .right {
        left: 0%;
    }
}

.my-card {
    border: 1px solid var(--borderColor);
    background-color: var(--darkBgColor);
    border-radius: 50px;
    box-shadow: 0 2px 20px gray;
    justify-content: space-between;
    transition: transform 200mx ease-in;
}

.my-card:hover {
    transform: scale(1.05);
    border: 5px solid var(--lightBgColor);

}

.social-icon {
    transition: transform 500mx ease-in;
}

.social-icon:hover {
    transform: scale(1.75);
}

.card {
    background-color: var(--darkBgColor);
    border: 1px solid var(--borderColor);
    border-radius: 50px;
    box-shadow: 0 2px 20px gray;
    justify-content: space-between;
    transition: transform 200mx ease-in;
}

.card-img-top {
    width: 100%;
    object-fit: cover;
}

.accordion-bg {
    background-color: lightgray;
    border: 1px solid var(--borderColor);
    box-shadow: 0 2px 20px gray;
    justify-content: space-between;
}

.accordion-content {
    background-color: var(--darkBgColor);
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 23rem));
    grid-gap: 5rem;
    justify-content: center;
}

/* Testimonial carousel */
.wrapper {
    padding: 50px 0;
    position: relative;
    margin: 120px, 0;
}

.imgBox {
    width: 100px;
    height: 100px;
    margin-left: 44%;
    border: 3px dashed var(--secondaryText);
    border-radius: 50%;
    overflow: hidden;
}

.imgBox img {
    width: 100%;
}

.carousel-inner {
    background: var(--darkBgColor);
    padding: 35px;
    border-radius: 25px;
    box-shadow: 0 2px 20px white;
}

.carousel-caption {
    position: static;
    padding-bottom: 15px;
}

.carousel-indicators button {
    width: 60px;
}

.carousel-indicators .active {
    background-color: var(--secondaryText);
    width: 10px;
}

.inner {
    width: 60%;
    margin: 0 auto;
}

.chatbot-container {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.chatbot-window {
    color: black;
}

.user-message {
    background-color: grey;
    color: #004d99;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.chatbot-message {
    background-color: skyblue;
    color: #333;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}